export default {

	namespaced:true,

	state : () => ({
		usuario:{
			id:null,
			nombre:'',
      apellido:'',
      email:null,
      fecha_nacimiento:null,
      telefono:null,
      genero:1, // 1 => Masc , 2 => Fem
      rol_id:null,
			imagen:null,
      activo:null,
      is_password:false,
      ultimo_login:null,
      estado_id:null,
      ciudad_id:null,
			direccion:'',
      locale:'es',
			travelier_code:'',
			negocio:null,
			conectado:false,
		},

		user:{	
			id:null,
			nombre:'',
      apellido:'',
      email:null,
      fecha_nacimiento:null,
      telefono:null,
      genero:1, // 1 => Masc , 2 => Fem
      rol_id:null,
			imagen:null,
      activo:null,
      is_password:false,
      ultimo_login:null,
      estado_id:null,
      ciudad_id:null,
			direccion:'',
      locale:'es',
			travelier_code:'',
			negocio:null,
			conectado:false,



		},

		usuarios:[]


	}),

	getters:{

		draft(state){
			return clone(state.user);
		},

		draftUsuario(state){
			return clone(state.usuario)
		},

		getTipoPersona(state){
			return (fecha_nacimiento) => {

				if(fecha_nacimiento){
					const edad  = moment().diff(moment(fecha_nacimiento),'years');
					if(edad < 8){
						return 'Infante'
					}else if(edad >= 8 && edad < 16){
						return 'Menor'
					}else{
						return 'Adulto'
					}
				}

				return ''

			}
		},

		conPermiso:(state) => {
			return (permiso) => {

				if(state.usuario){
					return (state.usuario.roles[0].permissions.find((permission) => permission.name == permiso))
				}

				return false;
				
			}
		},

		getUsuarios: (state) => {
			return (rol) => {
				return state.usuarios.filter(user => {
					

					let i  = user.roles.findIndex((val,i ) => roles.name == rol)
					if(i > 0 ){
						return true;
					}else{
						return false;
					}

				});
			} 
		},

		getUsuario:(state) => {
			return (id) => {
				return state.usuarios.find(user => user.id ==  id);
			}
		},

		getListado:(state) => {
			return (users_id) => {

				var users = [];

				users_id.forEach((e,i) => {

					var us = state.usuarios.find((u) => {

					 return (u.id == e.user_id);
					})

					if(us != undefined){
						if(users.find((u) => u.id == us.id)  == undefined){
							users.push(us);
						}
						
					}
					
				});


				return users;

			}
		},

		isRol(state){
			return (rol) => {
				if(typeof rol == 'object'){
					if(state.usuario.rol){
						return rol.includes(state.usuario.rol.nombre)
					}
					return false;
				}else{
					if(state.usuario.rol){
						return rol == state.usuario.rol.nombre
					}
					return false;
				}
				
			}
		},

		getFullName : (state) => `${state.usuario.nombre} ${state.usuario.apellido}`, 

		avatar:(state) => state.usuario.imagen,

		getFilterUsers:(state) => {
			return (roles_name) => {

				let result =  state.usuarios.filter(val => {
					return (roles_name.find(value => value == val.rol.nombre) != undefined) ? true : false
				});
				
				return result.map(val => {
					return {label:val.nombre,value:val.id,id:val.id,email:val.email};
				})
			}
		},

		rolUser(state){
			return state.usuario.rol ? state.usuario.rol.nombre : ''
		},

		rolName(state){
			return state.usuario.rol ? state.usuario.rol.nombre : '';
		},

		getLocale(state){
			return state.usuario.locale
		}

	},

	mutations:{

		cargarUser(state,data){
			localStorage.setItem('userData',JSON.stringify(data))			
			state.usuario = {...state.usuario,...data};
		},

		setTelefono(state,numero){
			state.usuario.telefono = numero
		},

		setUsuarios(state,usuarios){
			if(usuarios.length){
				state.usuarios = usuarios;
			}
		},

		
		setUsuario(state,usuario){
			if(usuario != undefined || usuario != null){
				state.user = usuario
			}
			
		},

		pushUsuario(state,usuario){
			state.usuarios.push(usuario);
		},

		capturarUsuario(state,id_usuario){
			state.user = state.usuarios.find((user) => user.id == id_usuario)
		},

		clearUsuario(state){
			state.user = {
				id:null,
				nombre:'',
				apellido:'',
				email:null,
				fecha_nacimiento:null,
				telefono:null,
				genero:1, // 1 => Masc , 2 => Fem
				rol_id:null,
				imagen:null,
				activo:null,
				is_password:false,
				ultimo_login:null,
				estado_id:null,
				ciudad_id:null,
				direccion:'',
				locale:'es',
				travelier_code:'',
				negocio:null,
				conectado:false,
			}
		},

		updateUsuario(state,data){
			var i = state.usuarios.findIndex((user) => user.id == data.id);

			if(i != -1){
				state.usuarios[i] = data;
				state.user = data;
			}

		},

		update(state,data){

			var i = state.usuarios.findIndex((user) => user.id == data.id);
			
			if(i != -1){
				state.usuarios[i] = data;
			}

		},

		updateAvatarPerfil(state,avatar){
			const user = JSON.parse(localStorage.getItem('userData'))
			user.imagen = avatar;
			user.avatar = avatar
			localStorage.setItem('userData',JSON.stringify(user))
			state.usuario.avatar = avatar;
			state.usuario.imagen = avatar;

		},

		actualizarAvatarDeUsuario(state,avatar){
			let user = state.usuarios.find(val => val.id == state.user.id)

			if(user != undefined){
				user.imagen = avatar
			}

		},

		updatePerfil(state,data){
			localStorage.setItem('userData',JSON.stringify(data))
			state.usuario = {...state.usuario,...data}
		},

		actualizarNegocioUsuario(state,negocio){
			if(negocio){
				state.usuario.negocio = negocio
				localStorage.setItem('userData',JSON.stringify(state.usuario))
			}
		},


		desactivarCuenta(state, result) {
			const user = JSON.parse(localStorage.getItem('userData'))
			user.activo = !result;
			localStorage.setItem('userData', JSON.stringify(user))
			state.usuario.activo = !result;

		},

		limpiarUsuario(state){

			state.usuario = {
				id:null,
				nombre:'',
				apellido:'',
				email:null,
				fecha_nacimiento:null,
				telefono:null,
				genero:1, // 1 => Masc , 2 => Fem
				rol_id:null,
				imagen:null,
				activo:null,
				is_password:false,
				ultimo_login:null,
				estado_id:null,
				ciudad_id:null,
				direccion:'',
				locale:'es',
				travelier_code:null,
				negocio:null,
				conectado:false,
			}

		},

		updateLocale(state,locale){
			localStorage.setItem('locale',locale)
			state.usuario.locale = locale
		},

		updateAvatar(state,{user_id,avatar}){
	
			// if(state.usuario.id === user_id){
			// 	state.usuario.avatar = avatar
			// }	

			if(state.user.id === user_id){
				state.user.avatar = avatar
			}

		}
	},

	actions:{

		updateLocale({commit},{usuario,locale}){

			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario}/update-locale/${locale}`).then(({data}) => {
					if(data.result){
						commit('updateLocale',locale)
					}
					resolve(data)
				}).catch(e => reject(e))
				
			})
		},

		cargarLightUsers({commit}){

			return new Promise((resolve, reject) => {
				
				axios.get(`/api/usuarios/get/list-select-light`).then(({data}) => {
					resolve(data)
				}).catch((e) => reject(e))

			})
		},

		cargarLightUsersParaBonos({commit},rols_name){
			return new Promise((resolve,reject) => {
				axios.post(`/api/usuarios/get/list-select-light-para-bonos`,{rols_name}).then(({data}) => resolve(data)).catch(e => reject(e))
			})
		},


		usuariosListSelectLight({commit}){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/get/list-select-light-con-cuenta`).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))
			})
		},

		cargarUsuarios({state,commit}){

			return new Promise((resolve, reject) => {
				axios.get('/api/usuarios/all').then(({data}) => {
					commit('setUsuarios',data);
					resolve(data)
				}).catch( e => reject(e))
			})

		},

		cargarUsuario({state,commit,dispatch}){

				// return await axios.get('/app/get/data');
				let options = {
					'headers': {
						'WWW-Authenticate': 'Bearer', 'Authorization': localStorage.getItem('token')
					}
				}
				return new Promise((resolve, reject) => {
					axios.get(`/api/auth/user/`,null,options).then(({data}) => {
						commit('cargarUser',data)
						resolve(data)
					}).catch(e => reject(e))

				})

		},

		async guardar({state,commit,dispatch},datos){

			return new Promise((resolve, reject) => {
				if (datos.id) {
						
						axios.put(`/api/usuarios/` + datos.id, datos).then(({data}) => {
							resolve(data)
						}).catch(e => reject(e))
				} else {
					
					axios.post('/api/usuarios', datos).then(({data}) => {
						resolve(data)
					 }).catch(e => reject(e))
				}
			
			})
			
	   
		},

		async guardarUsuario({state,commit},datos){

			return await new Promise((resolve, reject) => {
				axios.put(`/api/perfil/update/usuario/${state.usuario.id}`,datos).then(({data}) => {
					if(data.result){
						commit('updatePerfil',data.usuario)
					}
					resolve(data)
				}).catch(e => reject(e))

			})
		},

		async cambiarContrasena({commit,state},data){
			return await new Promise((resolve, reject) => {
				axios.put(`/api/cambiar/contrasena/usuario/${state.usuario.id}`,data).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))

			})
		},
		 

		async eliminar({state},usuario_id){

			return await new Promise((resolve, reject) => {
				axios.delete(`/api/usuarios/${usuario_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
			})

		},

		updateAvatarUser({state,commit},datos){

			return new Promise((resolve, reject) => {
				let form = new FormData();
				form.append('imagen', datos.imagen);

				axios.post(`/api/usuario/${datos.user_id}/update/avatar`,form,{
					headers: {
						'Content-Type': 'multipart/form-data; boundary=something'
					}
				}).then(({data}) => {
					if(data.result){
						commit('updateAvatar',{user_id:datos.user_id,avatar:data.avatar})
						if(state.usuario.id === datos.user_id){
							commit('updateAvatarPerfil',data.avatar)
						}
					}
					resolve(data)
				})
				.catch(e => reject(e));

			});

		},

		fetchData({state,commit},datos){

				return new Promise((resolve, reject) => {
					axios.post('/api/usuarios/fetch-data', datos)
						.then(({data}) => {
							resolve(data)
						})
						.catch(error => reject(error))
				});
		},

		fetch({state,commit},usuario_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario_id}/fetch-data`).then(({data}) => {
					commit('setUsuario',data)
					resolve(data)
				}).catch(e => reject(e))
			})
		},

		fetchDataUserForEmail({state,commit},email){
			return new Promise((resolve, reject) => {
				
				axios.get(`/api/usuarios/for-email/${email}/fetch-data`).then(({data}) => {
					commit('setUsuario',data)
					resolve(data)
				}).catch(e => reject(e))

			})
		},

		async desactivarCuenta({commit},data) {
			return await new Promise((resolve, reject) => {
				axios.post(`/api/desactivar/usuario`,data).then(({data:datos}) => {
					commit('desactivarCuenta',datos.result)
					resolve(datos)
				}).catch( e => reject(e))
			})
		},

		async nuevoUsuario({commit},datos){

			return await new Promise((resolve, reject) => {
				axios.post('/api/auth/nuevo/usuario',datos).then(({data}) => {
					resolve(data)
				}).catch(e => {
					reject(e)
				})

			})

		},

		fetchUser({state,commit},id_user){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${id_user}/fetch-data-user`).then(({data}) => {
					commit('setUsuario',data)
					resolve(data)
				}).catch(e => reject(e))
			})
		},


		verificarStatus({state,commit},usuario_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario_id}/check/is-verified`).then(({data}) => {resolve(data)}).catch(e => reject(e))
			})
		},

		cambiarStatus({commit},datos){
			return new Promise((resolve, reject) => {
				axios.put(`/api/usuarios/${datos.user_id}/change-status`,datos).then(({data}) => resolve(data))
				.catch(e => reject(e))
			})
		},

		cargarPromotoresSinAgencias({commit}){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/promotores/sin-agencia`).then(({data}) => resolve(data)).catch(e => reject(e));
			})
		},

		cargarUsuariosAgencias({commit}){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/rol-agencias`).then(({data}) => resolve(data)).catch(e => reject(e));
			})
		},

		cargarChoferesSinTransporte({commit}){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/choferes/sin-transportista`).then(({data}) => resolve(data)).catch(e => reject(e));
			})
		},

		cargarCupones({state,commit},usuario_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/${usuario_id}/get-cupons`).then(({data}) => resolve(data)).catch(e => reject(e))
			})
		},

		getListPorRol({commit},rol_name){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/rol/${rol_name}/get-list-select`).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))
			})
		},

		getPromotoresAgencia({commit},agencia_id){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/promotores/agencias/${agencia_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
			})
		},


		cargarReservas({commit},datos){
			return new Promise((resolve, reject) => {
				axios.put(`/api/usuarios/${datos.model_id}/rol/${datos.rol}/chart-reservas`,datos).then(({data}) => resolve(data)).catch(e => reject())
			})
		},

		fetchDataIngresos({commit},datos){
			return new Promise((resolve, reject) => {
				
				axios.post(`/api/usuarios/fetch-data-ingresos`,datos).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))

			})
		},

		diasTrabajadoTravelier({commit},usuario_id){
			return new Promise((resolve, reject) => {
				
				axios.get(`/api/usuarios/${usuario_id}/get/dias-trabajados-como-travelier`)
				.then(({data}) => resolve(data))
				.catch(e => reject(e))

			})
		},

		fetchDataIngresosDiarios({commit},datos){

			return new Promise((resolve, reject) => {
				axios.post(`/api/usuarios/ingresos-diarios/fetch-data`,datos).then(({data}) => resolve(data)).catch(e => reject(e)) 
			})
		},


		cargarTableroTravelier({commit},{usuario_id,filtro}){
			return new Promise((resolve, reject) => {
				
				axios.put(`/api/usuarios/${usuario_id}/tablero/travelier`,filtro).then(({data}) => resolve(data)).catch(e => reject(e))
				
			})
		},
		cargarTableroSuperAdmin({commit},{filtro}){
			return new Promise((resolve, reject) => {
				axios.put(`/api/usuarios/tablero/super-admin`,filtro).then(({data}) => resolve(data)).catch(e => reject(e))
			})
		},


		cargarTraveliersConGastosPromedio({commit}){
			return new Promise((resolve, reject) => {
					axios.get(`/api/usuarios/traveliers/tablero/gasto-promedio-por-pasajero`).then(({data}) => {
						resolve(data)
					}).catch(e => reject(e))
			})
		},

		satisfaccionPasajeroTravelier({commit},{travelier_id,filtro}){
			return new Promise((resolve, reject) => {
				 axios.put(`/api/traveliers/${travelier_id}/satisfaccion-generada`,filtro).then(({data}) => resolve(data)).catch(e => reject(e))
			})
		},

		satisfaccionPasajeroConTraveliers({commit},filtro){
			return new Promise((resolve, reject) => {
				axios.post(`/api/traveliers/satisfaccion-generada`,filtro).then(({data}) => resolve(data)).catch(e => reject(e))
			})
		},


		rolTrabajoDiasActivos({commit},{usuario_id}){
			return new Promise((resolve, reject) => {
				
				axios.post(`/api/usuario/rol-trabajo/dias-activos`,{usuario_id}).then(({data}) => {
					resolve(data)
				}).catch(e => {
					reject(e)
				})

			})
		},


		getUsersPorRol({commit},rol_name){
			return new Promise((resolve, reject) => {
				axios.get(`/api/usuarios/rol/${rol_name}/get-list-select`).then(({data}) => {
					resolve(data)
				}).catch(e => reject(e))
				
			})
		},

		resultadoSemanal({commit},{usuario_id,primer_dia,ultimo_dia}){
			return new Promise((resolve, reject) => {
				 
					axios.put(`/api/usuarios/${usuario_id}/reporte-semanal`,{primer_dia,ultimo_dia}).then(({data}) => {
					resolve(data)
				 }).catch(e => {
					reject(e)
				 })

			})
		},

		fetchDataTotalIngresos({commit},filtro){
			return new Promise((resolve, reject) => {
				axios.post(`/api/usuarios/reportes/fetch-data-ingresos`,filtro).then(({data}) => resolve(data)).catch(e => reject(e));
				
			})
		}

	}
}