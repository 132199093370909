export default {
  namespaced:true,
  state:() =>({
    
    bono:{
      id:null,
      tipo:1, // [1 => Bono Pasajeros Show por fechas, 2 => "Por ventas de boletos en agencias"] 
      beneficiario_id:null,
      concepto_acreditacion:'',
      variables_pasajeros:[],// [['pasajeros' => 4,'pagar' => 25,mayor => true],...]
      variables_boletos:{
        agencias:[],
        porcentaje:5
      }, // {agencias: [ids,...],porcentaje:5]}
    },

    bonos:[],
    optionsTipo:[
      {text:'Bono por pasajeros show',value:1},
      {text:'Bono por ventas de boletos',value:2},
    ],

    variable_pasajeros:{
      pasajeros:4,
      pagar:25,
      mayor:true
    }

  }),

  getters:{
    draft:(state) => clone(state.bono),
    optionsTipo:(state) => state.optionsTipo
  },



  mutations:{

    clear(state){
      state.bono = {
       id:null,
        tipo:1, // [1 => Bono Pasajeros Show por fechas, 2 => "Por ventas de boletos en agencias"] 
        beneficiario_id:null,
        concepto_acreditacion:'',
        variables_pasajeros:[],// [['pasajeros' => 4,'pagar' => 25,mayor => true],...]
        variables_boletos:{
          agencias:[],
          porcentaje:5
        }, // {agencias: [ids,...],porcentaje:5]}
      }
    },


    setBonos(state,bonos){
      if(bonos){
        state.bonos = bonos
      }
    },


    setBono(state,bono){
      if( bono){
        state.bono = bono
      }
    },

    pushVariablePasajeros(state){
      state.bono.variables_pasajeros.push({
        ...state.variable_pasajeros,
        pasajeros:state.bono.variables_pasajeros.length ? state.bono.variables_pasajeros[(state.bono.variables_pasajeros.length) - 1].pasajeros + 1: 4
      })
    },

    quitarVariablePasajeros(state,index){
      state.bono.variables_pasajeros.splice(
        index,
        1
      );
    },

    flushVariablesPasajeros(state) {
      state.bono.variables_pasajeros = [];
    },

    flushVariablesBoletos(state) {
      state.bono.variables_boletos = [];
    }



  },


  actions:{

    fetch({commit},bono_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/bonos/${bono_id}/fetch-data`).then(({data}) => {
          commit('setBono',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/bonos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      return new Promise((resolve, reject) => {
        if(datos.id){
          axios.put(`/api/bonos/${datos.id}`,datos).then(({data}) => {
              resolve(data)
          }).catch(e => reject(e))
        }else{
          axios.post(`/api/bonos`,datos).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))


        }
      })
    },

    eliminar({state,commit},bono_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/bonos/${bono_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    }

  }

}